const data = [
    {
        id: 1,
        title: 'القهوة الساخنة',
        image: '../../../img/list/coffe-cup.png',
        selected: true,
        items: [
            {
                id: 1,
                title: 'كابتشينو',
                description: '',
                image: '../../img/food/capatchino.png',
                price: 16,
                calories: 81,
                selected: true,
            },
            {
                id: 2,
                title: 'إسبرسو',
                description: '',
                image: '../../img/food/ispressop.png',
                price: 10,
                calories: 2,
                selected: true,
            },
            {
                id: 3,
                title: 'لاتية',
                description: '',
                image: '../../img/food/late.png',
                price: 16,
                calories: 119,
                selected: true,
            },
            {
                id: 4,
                title: 'أمريكانو',
                description: '',
                image: '../../img/food/amrico.png',
                price: 12,
                calories: 2,
                selected: true,
            },
            {
                id: 5,
                title: 'شوكلاته ساخن',
                description: '',
                image: '../../img/food/moka.png',
                price: 16,
                calories: 241,
                selected: true,
            },
            {
                id: 6,
                title: 'فلات وايت',
                description: '',
                image: '../../img/food/cortado.png',
                price: 12,
                calories: 100,
                selected: true,
            },
            {
                id: 7,
                title: 'كافيه موكا',
                description: '',
                image: '../../img/food/moka.png',
                price: 16,
                calories: 311,
                selected: true,
            },
            {
                id: 8,
                title: 'قهوة تركية',
                description: '',
                image: '../../img/food/turkish.png',
                price: 12,
                calories: 2,
                selected: true,
            },
            {
                id: 9,
                title: 'لاتيه كراميل',
                description: '',
                image: '../../img/food/cortado.png',
                price: 16,
                calories: 180,
                selected: true,
            },
            {
                id: 10,
                title: 'لاتيه فانيلا',
                description: '',
                image: '../../img/food/ispressop.png',
                price: 16,
                calories: 180,
                selected: true,
            },
            {
                id: 11,
                title: 'لاتيه بندق',
                description: '',
                image: '../../img/food/frnch.jpg',
                price: 16,
                calories: 180,
                selected: true,
            },
            {
                id: 12,
                title: 'قهوة سعودية',
                description: '',
                image: '../../img/food/dlah.png',
                price: 20,
                calories: 10,
                selected: true,
            },
            {
                id: 13,
                title: 'أضف نكهات مميزة',
                description: 'يمكن لنكهات الشراب المركز للقهوة المختلفة ان تغير مذاق قهوتك اليومية .',
                image: '../../img/food/nkhat.jpg',
                price: 3,
                calories: 0,
                selected: true,
            },
        ]
    },
    {
        id: 2,
        title: 'القهوة الباردة',
        image: '../../../img/list/bard.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'آيس موكا لاتيه',
                description: '',
                image: '../../img/food/icemoca.png',
                price: 16,
                calories: 229,
                selected: false,
            },
            {
                id: 2,
                title: 'آيس بستاشيو لاتيه',
                description: '',
                image: '../../img/food/bstachio.png',
                price: 18,
                calories: 417,
                selected: false,
            },
            {
                id: 3,
                title: 'آيس كراميل لاتيه',
                description: '',
                image: '../../img/food/caramel.png',
                price: 18,
                calories: 390,
                selected: false,
            },
            {
                id: 4,
                title: 'آيس سبانيش لاتيه',
                description: '',
                image: '../../img/food/spanish.png',
                price: 16,
                calories: 201,
                selected: false,
            },
            {
                id: 5,
                title: 'آيس شوكلاته لاتيه',
                description: '',
                image: '../../img/food/spanish.png',
                price: 16,
                calories: 361,
                selected: false,
            },
            {
                id: 6,
                title: 'آيس لاتيه',
                description: '',
                image: '../../img/food/fanila.png',
                price: 16,
                calories: 310,
                selected: false,
            },
            {
                id: 7,
                title: 'ريد فيلفت',
                description: '',
                image: '../../img/food/redf.jpeg',
                price: 16,
                calories: 320,
                selected: false,
            },
            {
                id: 8,
                title: 'آيس أمريكانو',
                description: '',
                image: '../../img/food/iceamricano.png',
                price: 12,
                calories: 5,
                selected: false,
            },
            {
                id: 9,
                title: 'موكا فرابية',
                description: '',
                image: '../../img/food/FRAPEMOCHA.webp',
                price: 18,
                calories: 229,
                selected: false,
            },
            {
                id: 10,
                title: 'كراميل فرابية',
                description: '',
                image: '../../img/food/caramelfrape.jpg',
                price: 18,
                calories: 390,
                selected: false,
            },
            {
                id: 11,
                title: 'شوكلاته فرابية',
                description: '',
                image: '../../img/food/icemocafrape.jpg',
                price: 16,
                calories: 361,
                selected: false,
            },
        ]
    },
    {
        id: 3,
        title: 'الشاي',
        image: '../../../img/list/tea.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'شاي أحمر',
                description: '',
                image: '../../img/food/shahya7mr.png',
                price: 19,
                calories: 337,
                selected: false,
            },
            {
                id: 2,
                title: 'شاي أخضر',
                description: 'كوب - براد',
                image: '../../img/food/shahya5dr.png',
                price: 19,
                calories: 2,
                selected: false,
            },
            {
                id: 3,
                title: 'شاي مغربي',
                description: 'كوب - براد',
                image: '../../img/food/mghrbi.png',
                price: 19,
                calories: 14,
                selected: false,
            },
            {
                id: 4,
                title: 'شاي كرك',
                description: 'كوب - براد',
                image: '../../img/food/adni.png',
                price: 10,
                calories: 337,
                selected: false,
            },
            {
                id: 5,
                title: 'شاي حليب',
                description: 'كوب - براد',
                image: '../../img/food/shaymilk.webp',
                price: 19,
                calories: 37,
                selected: false,
            },
            {
                id: 6,
                title: 'شاي بابونج',
                description: 'كوب - براد',
                image: '../../img/food/babonj.jpeg',
                price: 19,
                calories: 2,
                selected: false,
            },
            {
                id: 7,
                title: 'شاي ياسمين',
                description: 'كوب - براد',
                image: '../../img/food/yasmen.avif',
                price: 19,
                calories: 2,
                selected: false,
            },
            {
                id: 8,
                title: 'شاي زنجبيل',
                description: 'كوب - براد',
                image: '../../img/food/znjbel.webp',
                price: 19,
                calories: 2,
                selected: false,
            },
            {
                id: 9,
                title: 'شاي جينجر بريد',
                description: 'كوب - براد',
                image: '../../img/food/znjrbred.jpg',
                price: 10,
                calories: 325,
                selected: false,
            },
        ]
    },
    {
        id: 4,
        title: 'الحلا',
        image: '../../../img/list/soit.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'نيويورك تشيز كيك شوكلاتة',
                description: '',
                image: '../../img/food/Chocolate_New.jpg',
                price: 18,
                calories: 960,
                selected: true,
            },
            {
                id: 2,
                title: 'ريد فيلفت كيك',
                description: '',
                image: '../../img/food/Red_Velvet.jpg',
                price: 17,
                calories: 810,
                selected: true,
            },
            {
                id: 3,
                title: 'نيويورك تشيز كيك بالتوت',
                description: '',
                image: '../../img/food/chezkik.jpg',
                price: 20,
                calories: 950,
                selected: true,
            },
            {
                id: 4,
                title: 'سان سبستيان',
                description: '',
                image: '../../img/food/San_Sebastian.jpg',
                price: 18,
                calories: 275,
                selected: true,
            },
            {
                id: 5,
                title: 'كيكة الزعفران',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 282,
                selected: true,
            },
            {
                id: 6,
                title: 'تشيز كيك بستاشيو',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 420,
                selected: true,
            },
            {
                id: 7,
                title: 'ترافل رويال',
                description: '',
                image: '../../img/items.png',
                price: 17,
                calories: 430,
                selected: true,
            },
            {
                id: 8,
                title: 'كرافن دونات',
                description: '',
                image: '../../img/food/Donut.jpg',
                price: 7,
                calories: 385,
                selected: true,
            },
            {
                id: 9,
                title: 'كوكيز القمح الفاخر شوكلاتة',
                description: '',
                image: '../../img/items.png',
                price: 6,
                calories: 250,
                selected: true,
            },
            {
                id: 10,
                title: 'كوكيز القمح الفاخر دبل شوكلاتة',
                description: '',
                image: '../../img/items.png',
                price: 6,
                calories: 400,
                selected: true,
            },
            {
                id: 11,
                title: 'مولتن شوكولاتة أبيض',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 490,
                selected: true,
            },
            {
                id: 12,
                title: 'شوكولاته مولتن كيك',
                description: '',
                image: '../../img/food/Molten_Chocolate.jpg',
                price: 18,
                calories: 520,
                selected: true,
            },
            {
                id: 13,
                title: 'تمر مولتن كيك',
                description: '',
                image: '../../img/items.png',
                price: 18,
                calories: 307,
                selected: true,
            },
            {
                id: 14,
                title: 'تراميسو',
                description: '',
                image: '../../img/food/Tiramissu.jpg',
                price: 18,
                calories: 308,
                selected: true,
            },
            {
                id: 15,
                title: 'شوكلاتة سوفليه مع ايسكريم',
                description: '',
                image: '../../img/food/Sufle.jpg',
                price: 20,
                calories: 935,
                selected: true,
            },
            {
                id: 16,
                title: 'وافل بستاشيو و نوتيلا ',
                description: '',
                image: '../../img/food/Nutella_Waffle.jpg',
                price: 18,
                calories: 233,
                selected: true,
            },
            {
                id: 17,
                title: 'بان كيك بستاشيو و نوتيلا',
                description: '',
                image: '../../img/food/banNutella.jpg',
                price: 18,
                calories: 263,
                selected: true,
            },
        ]
    },
    {
        id: 5,
        title: 'الموهيتو',
        image: '../../../img/list/mohi.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'موهيتو فراولة',
                description: '',
                image: '../../img/food/rozberry.png',
                price: 16,
                calories: 100,
                selected: false,
            },
            {
                id: 2,
                title: 'موهيتو توت أزرق',
                description: '',
                image: '../../img/food/bleuberry.jpeg',
                price: 16,
                calories: 100,
                selected: false,
            },
            {
                id: 3,
                title: 'موهيتو روز بيري',
                description: '',
                image: '../../img/food/rozberry.png',
                price: 16,
                calories: 100,
                selected: false,
            },
            {
                id: 4,
                title: 'موهيتو باشن فروت',
                description: '',
                image: '../../img/food/mamarita.png',
                price: 16,
                calories: 100,
                selected: false,
            },
            {
                id: 5,
                title: 'موهيتو بطيخ',
                description: '',
                image: '../../img/food/mirbery.png',
                price: 16,
                calories: 100,
                selected: false,
            },
            {
                id: 6,
                title: 'موهيتو تروبيكال بلو',
                description: '',
                image: '../../img/food/torbikal.jpg',
                price: 16,
                calories: 100,
                selected: false,
            },
        ]
    },
    {
        id: 6,
        title: 'العصائر و الكوكتيلات',
        image: '../../../img/list/juc.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'عصير كوكتيل',
                description: '',
                image: '../../img/food/Cocktail.jpg',
                price: 16,
                calories: 217,
                selected: false,
            },
            {
                id: 2,
                title: 'عصير فراولة',
                description: '',
                image: '../../img/food/Strawberry.jpg',
                price: 14,
                calories: 240,
                selected: false,
            },
            {
                id: 3,
                title: 'عصير جوافة',
                description: '',
                image: '../../img/food/Guava.jpg',
                price: 14,
                calories: 240,
                selected: false,
            },
            {
                id: 4,
                title: 'عصير مانجو',
                description: '',
                image: '../../img/food/Manggo.jpg',
                price: 14,
                calories: 282,
                selected: false,
            },
            {
                id: 5,
                title: 'ليمون نعناع',
                description: '',
                image: '../../img/items.png',
                price: 16,
                calories: 250,
                selected: false,
            },
            {
                id: 6,
                title: 'كوكتيل سعودي',
                description: '',
                image: '../../img/items.png',
                price: 16,
                calories: 133,
                selected: false,
            },
            {
                id: 7,
                title: 'بنكولادا',
                description: '',
                image: '../../img/items.png',
                price: 16,
                calories: 660,
                selected: false,
            },
        ]
    },
    {
        id: 7,
        title: 'الملك شيك',
        image: '../../../img/list/milkshake.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'ملك شيك فراولة',
                description: '',
                image: '../../img/food/milkfraolh.jpg',
                price: 14,
                calories: 417,
                selected: false,
            },
            {
                id: 2,
                title: 'ملك شيك مانجو',
                description: '',
                image: '../../img/food/milkmanjo.jpg',
                price: 14,
                calories: 266,
                selected: false,
            },
            {
                id: 3,
                title: 'ملك شيك فانيلا',
                description: '',
                image: '../../img/food/milkfanila.jpg',
                price: 14,
                calories: 475,
                selected: false,
            },
            {
                id: 4,
                title: 'ملك شيك شوكلاتة',
                description: '',
                image: '../../img/food/milkchoklit.jpg',
                price: 14,
                calories: 588,
                selected: false,
            },
            {
                id: 5,
                title: 'ملك شيك كيت كات',
                description: '',
                image: '../../img/food/milkchoklit.jpg',
                price: 14,
                calories: 512,
                selected: false,
            },
            {
                id: 6,
                title: 'ملك شيك سنيكرز',
                description: '',
                image: '../../img/food/milkchoklit.jpg',
                price: 14,
                calories: 629,
                selected: false,
            },
            {
                id: 7,
                title: 'ملك شيك أوريو',
                description: '',
                image: '../../img/food/milkchoklit.jpg',
                price: 14,
                calories: 574,
                selected: false,
            },
            {
                id: 8,
                title: 'ملك شيك لوتس',
                description: '',
                image: '../../img/food/milkfanila.jpg',
                price: 14,
                calories: 650,
                selected: false,
            },
        ]
    },
    // {
    //     id: 8,
    //     title: 'الفطور',
    //     image: '../../../img/list/breakfast.png',
    //     selected: false,
    //     items: [
    //         {
    //             id: 1,
    //             title: 'الفطور التركي',
    //             description: 'يشمل الفطور , بيض , فول , حمص , متبل .. إلخ',
    //             image: '../../img/food/Turkish_Breakfast.jpg',
    //             price: 48,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 2,
    //             title: 'الفطور الشرقي',
    //             description: 'يشمل الفطور , بيض , فول , حمص , متبل .. إلخ',
    //             image: '../../img/food/Shargi_Breakfast.jpg',
    //             price: 36,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 3,
    //             title: 'حمص باللحمة',
    //             description: '',
    //             image: '../../img/food/7m9bl7m.jpg',
    //             price: 16,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 4,
    //             title: 'متبل',
    //             description: '',
    //             image: '../../img/food/motbl.jpg',
    //             price: 8,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 5,
    //             title: 'حمص',
    //             description: '',
    //             image: '../../img/food/7mo9.jpg',
    //             price: 8,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 6,
    //             title: 'فول بالطحينية',
    //             description: '',
    //             image: '../../img/food/fool-t7inh.jpg',
    //             price: 8,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 7,
    //             title: 'قلابة',
    //             description: '',
    //             image: '../../img/food/8labh.jpg',
    //             price: 8,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 8,
    //             title: 'قلابه بندورة بالجبنة',
    //             description: '',
    //             image: '../../img/food/8elaba-bndora.jpg',
    //             price: 11,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 9,
    //             title: 'مسبحة',
    //             description: '',
    //             image: '../../img/food/mosba7h.jpg',
    //             price: 8,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 10,
    //             title: 'كبدة',
    //             description: '',
    //             image: '../../img/food/kibdah.jpg',
    //             price: 18,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 11,
    //             title: 'لبنة',
    //             description: '',
    //             image: '../../img/food/lebnah.jpg',
    //             price: 8,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 12,
    //             title: 'جبنة حلومي',
    //             description: '',
    //             image: '../../img/food/hallomi-cheese.png',
    //             price: 10,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 13,
    //             title: 'مفركة بطاطا',
    //             description: '',
    //             image: '../../img/food/mofaraka.jpg',
    //             price: 11,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 14,
    //             title: 'شكشوكة',
    //             description: '',
    //             image: '../../img/food/shakshoka.jpg',
    //             price: 11,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 15,
    //             title: 'أومليت بالجبنة',
    //             description: '',
    //             image: '../../img/food/omlett-cheez.jpg',
    //             price: 10,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 16,
    //             title: 'أومليت أسباني',
    //             description: '',
    //             image: '../../img/food/omlett-ispani.jpg',
    //             price: 10,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 17,
    //             title: 'بيض عيون',
    //             description: '',
    //             image: '../../img/food/omlett-3yoon.jpg',
    //             price: 10,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 18,
    //             title: 'مناقيش جبنة',
    //             description: '',
    //             image: '../../img/food/mna8ish.jpg',
    //             price: 10,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 19,
    //             title: 'مناقيش زعتر',
    //             description: '',
    //             image: '../../img/food/mna8ish-z3tr.jpg',
    //             price: 10,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 20,
    //             title: 'كرات اللبنة',
    //             description: '',
    //             image: '../../img/food/corat-libnh_11zon.jpg',
    //             price: 7,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 21,
    //             title: 'زيتون أسود',
    //             description: '',
    //             image: '../../img/food/zyton-asod.jpg',
    //             price: 5,
    //             calories: '',
    //             selected: false,
    //         },
    //         {
    //             id: 22,
    //             title: 'مشكل مخلل',
    //             description: '',
    //             image: '../../img/food/mo5ll.jpg',
    //             price: 5,
    //             calories: '',
    //             selected: false,
    //         },
    //     ]
    // },
    // {
    //     id: 9,
    //     title: 'الوجبات الرئيسية',
    //     image: '../../../img/list/meal.png',
    //     selected: false,
    //     items: [
    //         {
    //             id: 1,
    //             title: 'دجاج روكتا',
    //             description: 'مع صوص المشروم',
    //             image: '../../img/food/djajrokta.jpg',
    //             price: 45,
    //             calories: 510,
    //             selected: false,
    //         },
    //         {
    //             id: 2,
    //             title: 'ستيك بيف تندرلوين',
    //             description: 'مع صوص الجريفي والخضار',
    //             image: '../../img/food/stekpif.jpg',
    //             price: 69,
    //             calories: 410,
    //             selected: false,
    //         },
    //         {
    //             id: 3,
    //             title: 'سموك سالمون',
    //             description: 'مشوي مع الخضار',
    //             image: '../../img/food/smoksalamon.jpg',
    //             price: 69,
    //             calories: 580,
    //             selected: false,
    //         },
    //         {
    //             id: 4,
    //             title: 'فخارة لحم',
    //             description: '',
    //             image: '../../img/food/f5arh-l7m.jpg',
    //             price: 42,
    //             calories: 370,
    //             selected: false,
    //         },
    //         {
    //             id: 5,
    //             title: 'فيلية السمك المشوي',
    //             description: '',
    //             image: '../../img/food/feleh-smk.jpg',
    //             price: 49,
    //             calories: 430,
    //             selected: false,
    //         },
    //         {
    //             id: 6,
    //             title: 'فخارة الدجاج',
    //             description: '',
    //             image: '../../img/food/f5arh-djaj.jpg',
    //             price: 34,
    //             calories: 490,
    //             selected: false,
    //         },
    //         {
    //             id: 7,
    //             title: 'فخارة فريكة روزيتو',
    //             description: '',
    //             image: '../../img/items.png',
    //             price: 39,
    //             calories: 250,
    //             selected: false,
    //         },
    //         {
    //             id: 8,
    //             title: 'فخارة كفتة بالطحينة',
    //             description: '',
    //             image: '../../img/items.png',
    //             price: 40,
    //             calories: 690,
    //             selected: false,
    //         },
    //         {
    //             id: 9,
    //             title: 'برياني لحم',
    //             description: '',
    //             image: '../../img/food/brianilhm.jpg',
    //             price: 45,
    //             calories: 1291,
    //             selected: false,
    //         },
    //         {
    //             id: 10,
    //             title: 'برياني دجاج',
    //             description: '',
    //             image: '../../img/food/brianidjaj.jpg',
    //             price: 40,
    //             calories: 930,
    //             selected: false,
    //         },
    //         {
    //             id: 11,
    //             title: 'دجاج تكا مع الرز',
    //             description: '',
    //             image: '../../img/food/djajtaka.jpg',
    //             price: 38,
    //             calories: 784,
    //             selected: false,
    //         },
    //         {
    //             id: 12,
    //             title: 'مشكل مشاوي',
    //             description: '',
    //             image: '../../img/food/mshklroz.jpg',
    //             price: 38,
    //             calories: 893,
    //             selected: false,
    //         },
    //         {
    //             id: 13,
    //             title: 'مسحب دجاج',
    //             description: '',
    //             image: '../../img/food/mos7b.jpg',
    //             price: 35,
    //             calories: 690,
    //             selected: false,
    //         },
    //     ]
    // },
    // {
    //     id: 10,
    //     title: 'المقبلات الساخنة',
    //     image: '../../../img/list/nuggets.png',
    //     selected: false,
    //     items: [
    //         {
    //             id: 1,
    //             title: 'دجاج عثملية',
    //             description: '',
    //             image: '../../img/food/3thmileh.jpg',
    //             price: 18,
    //             calories: 150,
    //             selected: false,
    //         },
    //         {
    //             id: 2,
    //             title: 'ديناميت شرمب',
    //             description: '',
    //             image: '../../img/food/Dynamite_Shrimp.jpg',
    //             price: 35,
    //             calories: 320,
    //             selected: false,
    //         },
    //         {
    //             id: 3,
    //             title: 'فتة باذنجان',
    //             description: '',
    //             image: '../../img/food/fta-ba4njan.jpg',
    //             price: 22,
    //             calories: 720,
    //             selected: false,
    //         },
    //         {
    //             id: 4,
    //             title: 'كبة',
    //             description: '',
    //             image: '../../img/food/kepah.jpg',
    //             price: 20,
    //             calories: 110,
    //             selected: false,
    //         },
    //         {
    //             id: 5,
    //             title: 'بطاطا بالجبنة',
    //             description: '',
    //             image: '../../img/food/btata-jbn.jpg',
    //             price: 26,
    //             calories: 720,
    //             selected: false,
    //         },
    //         {
    //             id: 6,
    //             title: 'ناتشوز باللحمة',
    //             description: '',
    //             image: '../../img/food/natchozl7m.jpg',
    //             price: 28,
    //             calories: 1250,
    //             selected: false,
    //         },
    //         {
    //             id: 7,
    //             title: 'سبرنج رول',
    //             description: '',
    //             image: '../../img/food/spring-rol.jpg',
    //             price: 18,
    //             calories: 250,
    //             selected: false,
    //         },
    //         {
    //             id: 8,
    //             title: 'كبة ارمنية',
    //             description: '',
    //             image: '../../img/food/Aramania_Kebah.jpg',
    //             price: 22,
    //             calories: 950,
    //             selected: false,
    //         },
    //         {
    //             id: 9,
    //             title: 'بطاطا',
    //             description: '',
    //             image: '../../img/food/petitos.png',
    //             price: 14,
    //             calories: 860,
    //             selected: false,
    //         },
    //         {
    //             id: 10,
    //             title: 'بطاطا ودجز',
    //             description: '',
    //             image: '../../img/items.png',
    //             price: 16,
    //             calories: 920,
    //             selected: false,
    //         },
    //     ]
    // },
    // {
    //     id: 11,
    //     title: 'السلطات والمقبلات الباردة',
    //     image: '../../../img/list/salat.png',
    //     selected: false,
    //     items: [
    //         {
    //             id: 1,
    //             title: 'سلطة سيزر',
    //             description: '',
    //             image: '../../img/food/sezersalat.jpg',
    //             price: 16,
    //             calories: 480,
    //             selected: false,
    //         },
    //         {
    //             id: 2,
    //             title: 'محمرة',
    //             description: '',
    //             image: '../../img/food/mo7mrh.jpg',
    //             price: 16,
    //             calories: 470,
    //             selected: false,
    //         },
    //         {
    //             id: 3,
    //             title: 'تبولة',
    //             description: '',
    //             image: '../../img/food/tpolh.jpg',
    //             price: 16,
    //             calories: 220,
    //             selected: false,
    //         },
    //         {
    //             id: 4,
    //             title: 'بابا غنوج',
    //             description: '',
    //             image: '../../img/food/bsba3noj.jpg',
    //             price: 18,
    //             calories: 90,
    //             selected: false,
    //         },
    //         {
    //             id: 5,
    //             title: 'متبل',
    //             description: '',
    //             image: '../../img/food/motbl.jpg',
    //             price: 16,
    //             calories: 210,
    //             selected: false,
    //         },
    //         {
    //             id: 6,
    //             title: 'حمص',
    //             description: '',
    //             image: '../../img/food/7mo9.jpg',
    //             price: 16,
    //             calories: 420,
    //             selected: false,
    //         },
    //         {
    //             id: 7,
    //             title: 'مكس لاتوس',
    //             description: '',
    //             image: '../../img/food/mix-latos.jpg',
    //             price: 16,
    //             calories: 80,
    //             selected: false,
    //         },
    //         {
    //             id: 8,
    //             title: 'يلنجي',
    //             description: '',
    //             image: '../../img/food/yanji.jpg',
    //             price: 18,
    //             calories: 80,
    //             selected: false,
    //         },
    //         {
    //             id: 9,
    //             title: 'فتوش',
    //             description: '',
    //             image: '../../img/food/ftoosh.jpg',
    //             price: 16,
    //             calories: 100,
    //             selected: false,
    //         },
    //         {
    //             id: 10,
    //             title: 'كينوا',
    //             description: '',
    //             image: '../../img/food/kinowa.jpg',
    //             price: 18,
    //             calories: 420,
    //             selected: false,
    //         },
    //         {
    //             id: 11,
    //             title: 'طبق مقبلات',
    //             description: '',
    //             image: '../../img/food/newmo.jpg',
    //             price: 25,
    //             calories: 603,
    //             selected: false,
    //         },
    //     ]
    // },
    // {
    //     id: 12,
    //     title: 'المشاوي التركية',
    //     image: '../../../img/list/kebab.png',
    //     selected: false,
    //     items: [
    //         {
    //             id: 1,
    //             title: 'كباب لحم اضنة',
    //             description: '',
    //             image: '../../img/food/kpap-adnh.jpg',
    //             price: 44,
    //             calories: 290,
    //             selected: false,
    //         },
    //         {
    //             id: 2,
    //             title: 'كباب اضنه لحم ( 1م ) ',
    //             description: '',
    //             image: '../../img/food/kbab1m.jpg',
    //             price: 68,
    //             calories: 290,
    //             selected: false,
    //         },
    //         {
    //             id: 3,
    //             title: 'كباب دجاج اضنة',
    //             description: '',
    //             image: '../../img/food/djaj-adnh.jpg',
    //             price: 42,
    //             calories: 280,
    //             selected: false,
    //         },
    //         {
    //             id: 4,
    //             title: 'مشاوي مشكل',
    //             description: '',
    //             image: '../../img/food/Mix_Grill.jpg',
    //             price: 54,
    //             calories: 1210,
    //             selected: false,
    //         },
    //         {
    //             id: 5,
    //             title: 'تكا دجاج',
    //             description: '',
    //             image: '../../img/food/tka-djaj.jpg',
    //             price: 42,
    //             calories: 190,
    //             selected: false,
    //         },
    //         {
    //             id: 6,
    //             title: 'تكا لحم',
    //             description: '',
    //             image: '../../img/food/tka-l7m.jpg',
    //             price: 46,
    //             calories: 280,
    //             selected: false,
    //         },
    //     ]
    // },
    {
        id: 13,
        title: 'الساندوتشات',
        image: '../../../img/list/sandwich.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كباب دجاج ساندوتش',
                description: '',
                image: '../../img/food/kbab-djaj-s.jpg',
                price: 20,
                calories: 765,
                selected: false,
            },
            {
                id: 2,
                title: 'كباب ساندوتش',
                description: '',
                image: '../../img/food/kbab-s.jpg',
                price: 22,
                calories: 640,
                selected: false,
            },
            {
                id: 3,
                title: 'تشيكن تكا ساندوتش',
                description: '',
                image: '../../img/food/chekin-tka.jpg',
                price: 20,
                calories: 620,
                selected: false,
            },
            {
                id: 4,
                title: 'دينجرس برجر',
                description: '',
                image: '../../img/food/dengers.jpg',
                price: 24,
                calories: 830,
                selected: false,
            },
            {
                id: 5,
                title: 'منهاتن برجر',
                description: '',
                image: '../../img/food/mnhatin.jpg',
                price: 26,
                calories: 920,
                selected: false,
            },
            {
                id: 6,
                title: 'ساندوتش صارما لحم ',
                description: '',
                image: '../../img/items.png',
                price: 22,
                calories: 920,
                selected: false,
            },
        ]
    },
    {
        id: 14,
        title: 'البيتزا',
        image: '../../../img/list/pizza.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'بيتزا باربكيو',
                description: 'حجم وسط - حجم كبير',
                image: '../../img/food/parpe.jpg',
                price: '22-32',
                calories: 1570,
                selected: false,
            },
            {
                id: 2,
                title: 'بيتزا بيروني',
                description: 'حجم وسط - حجم كبير',
                image: '../../img/food/byroni.jpg',
                price: '22-32',
                calories: 2200,
                selected: false,
            },
            {
                id: 3,
                title: 'بيتزا خضار',
                description: 'حجم وسط - حجم كبير',
                image: '../../img/food/piza-k.jpg',
                price: '18-22',
                calories: 1850,
                selected: false,
            },
        ]
    },
    // {
    //     id: 15,
    //     title: 'الباستا',
    //     image: '../../../img/list/pasta.png',
    //     selected: false,
    //     items: [
    //         {
    //             id: 1,
    //             title: 'فوتشيني الفريدو',
    //             description: '',
    //             image: '../../img/food/alfrid.jpg',
    //             price: 38,
    //             calories: 860,
    //             selected: false,
    //         },
    //         {
    //             id: 2,
    //             title: 'لازانيا باللحمة',
    //             description: '',
    //             image: '../../img/food/lazlhm.jpg',
    //             price: 40,
    //             calories: 950,
    //             selected: false,
    //         },
    //         {
    //             id: 3,
    //             title: 'بيني بينك صوص بالدجاج',
    //             description: '',
    //             image: '../../img/food/pinksos.jpg',
    //             price: 38,
    //             calories: 650,
    //             selected: false,
    //         },
    //     ]
    // },
    {
        id: 16,
        title: 'المشروبات الغازية',
        image: '../../../img/list/energy.png',
        selected: false,
        items: [
            {
                id: 1,
                title: 'كوكا كولا',
                description: '',
                image: '../../img/food/cocacola.jpeg',
                price: 4,
                calories: 0,
                selected: false,
            },
            {
                id: 2,
                title: 'كوكا كولا دايت',
                description: '',
                image: '../../img/food/cocacola-dait.jpeg',
                price: 4,
                calories: 0,
                selected: false,
            },
            {
                id: 3,
                title: 'سبرايت',
                description: '',
                image: '../../img/food/sprait.jpeg',
                price: 4,
                calories: 0,
                selected: true,
            },
            {
                id: 4,
                title: 'فانتا برتقال',
                description: '',
                image: '../../img/food/fanportkal.jpeg',
                price: 4,
                calories: 0,
                selected: true,
            },
            {
                id: 5,
                title: 'فانتا حمضيات',
                description: '',
                image: '../../img/food/hmthiat.jpeg',
                price: 4,
                calories: 0,
                selected: true,
            },
            {
                id: 6,
                title: 'مياة معدنية',
                description: '',
                image: '../../img/food/ber.png',
                price: 1,
                calories: 0,
                selected: true,
            },
        ]
    },
]
export default data 